<template>
  <div>

    <!-- Modal to notify user where to change setting -->
    <modal :show.sync="modaldesc" headerClasses="justify-content-center">
      <h4 slot="header" class="title title-up">{{ $t('upentrybtn') }} {{ $t('description') }}</h4>

      <div class="row" v-if="modal_alert">
        <div class="col-12">
          <div :class="`alert-${modal_alert_type}`" class="alert alert-dismissible fade show">
            <button type="button" aria-hidden="true" class="close" data-dismiss="alert" aria-label="Close"
                    v-on:click="modal_alert=false">
              <i class="nc-icon nc-simple-remove"></i>
            </button>
            <span>{{ modal_alert_msg }}</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <textarea v-model="stratinfo.strat_desc" class="form-control" rows="3" style="padding: 5px;"></textarea>
        </div>
      </div>

      <template slot="footer">
        <div class="py-3 mx-auto" v-if="modalinprogress">
          <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
        </div>
        <div class="left-side" v-if="modalinprogress == false">
          <p-button @click.prevent="modalinprogress=true;modal_alert=false;updateDesc()" link>{{
              $t('upentrybtn')
            }}
          </p-button>
        </div>
        <div class="divider" v-if="modalinprogress == false"></div>
        <div class="right-side" v-if="modalinprogress == false">
          <p-button type="danger" @click.prevent="closeModalDesc()" link>{{ $t('cancel') }}</p-button>
        </div>
      </template>
    </modal>
    <!-- End Modal -->

    <!-- In progress -->
    <div v-if="inprogress" class="text-center">
      <h2>{{ inprogresstxt }}</h2>
      <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
    </div>

    <div :class="`alert-${modal_alert_type}`" class="alert alert-dismissible fade show" v-if="modal_alert">
      <button type="button" aria-hidden="true" class="close" data-dismiss="alert" aria-label="Close"
              v-on:click="closeModal">
        <i class="nc-icon nc-simple-remove"></i>
      </button>
      <span>{{ modal_alert_msg }}</span>
    </div>

    <div class="row" v-if="inprogress == false">
      <div class="col-md-1">
        <p-button class="abit-up" round outline v-on:click="prevPage()" v-if="hasHistory">
          <i slot="label" class="nc-icon nc-minimal-left"></i>
          {{ $t('back') }}
        </p-button>
      </div>
      <div class="col-md-10">
        <h5>
          {{ $t('strategytxt') }}: {{ strategy_name }} {{ $t('detail') }}
          <strong v-if="adminusr"> ({{ strategy_acc }}) </strong>
        </h5>
      </div>
      <div class="col-md-1" v-if="showdata">
        <el-tooltip :content="`${$t('analysisinfo_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
          <p-button type="info" round icon v-on:click="dlMathAnalysis()">
            <i slot="label" class="nc-icon nc-alert-circle-i"></i>
          </p-button>
        </el-tooltip>
      </div>
    </div>

    <div class="row" v-if="inprogress == false && showdata">
      <div class="col-md-1">
        <p-button class="pull-right abit-up" type="warning" round icon v-on:click="modaldesc=true;" v-if="adminusr">
          <i class="fa fa-pencil"></i>
        </p-button>
      </div>
      <div class="col-md-10">
        <h5>{{ (strategy_desc) ? strategy_desc : $t('notavailable') }}</h5>
      </div>
      <div class="col-md-1">&nbsp;</div>
    </div>

    <hr/>

    <!-- Charts -->
    <stats-charts :activeUsersChart="activeUsersChart" :equityUsersChart="equityUsersChart"
                  :inprogress="inprogress" :showdata="showdata" :eqReportingStatus="eqReportingStatus"
                  :balReportingStatus="balReportingStatus" :eqChartInfo="eqChartInfo"
                  :balChartInfo="balChartInfo"></stats-charts>
    <!-- Charts END -->

  </div>
</template>
<script>
import Vue from 'vue'
import {Card} from 'src/components/UIComponents'
import {Tooltip} from 'element-ui';
import StatsCharts from 'src/components/UIComponents/Charts/StatisticsCharts';
import {Modal} from 'src/components/UIComponents'

Vue.use(require('vue-moment'))

const maxSteps = 10;
const user_data = JSON.parse(localStorage.getItem('user-info'));
const timercountdown = 1500;
const tooltipOptions = {
  tooltipFillColor: "rgba(0,0,0,0.5)",
  tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
  tooltipFontSize: 14,
  tooltipFontStyle: "normal",
  tooltipFontColor: "#fff",
  tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
  tooltipTitleFontSize: 14,
  tooltipTitleFontStyle: "bold",
  tooltipTitleFontColor: "#fff",
  tooltipYPadding: 6,
  tooltipXPadding: 6,
  tooltipCaretSize: 8,
  tooltipCornerRadius: 6,
  tooltipXOffset: 10,
};

export default {
  components: {
    Card, [Tooltip.name]: Tooltip,
    StatsCharts, Modal,
  },
  data() {
    return {
      activeUsersChart: {
        labels: [],
        drawdown: {
          data: [],
          options: {
            tooltips: tooltipOptions,
            scales: {
              yAxes: [{
                ticks: {
                  callback: function (value, index, values) {
                    return value + '%';
                  }
                },
                gridLines: {display: true}
              }],
              xAxes: [{
                time: {unit: 'day', stepSize: 1},
                type: 'time', ticks: {beginAtZero: true, stepSize: 1, maxTicksLimit: 10},
                gridLines: {display: true}
              }]
            }
          }
        },
        stats: {},
        percentualVolatilityEndurance: {
          data: [],
          options: {
            tooltips: tooltipOptions,
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  suggestedMax: 100,
                  callback: function (value, index, values) {
                    return value + '%';
                  },
                  reverse: true
                },
                gridLines: {display: true}
              }],
              xAxes: [{
                time: {unit: 'day', stepSize: 1},
                type: 'time', ticks: {beginAtZero: true, stepSize: 1, maxTicksLimit: 10},
                gridLines: {display: true}
              }]
            }
          },
        },
        color: '#6bd098',
        accumulatedBalance: {
          datasets: [
            {
              borderColor: '#51cbce', backgroundColor: '#51cbce',
              data: [], pointRadius: 0, fill: false
            }
          ]
        },
        dailyBalanceGrowth: {
          datasets: [
            {
              borderColor: '#51cbce', backgroundColor: '#51cbce',
              data: [], pointRadius: 0, fill: false
            }
          ]
        },
        effectiveLeverageUsed: {
          data: [],
          options: {
            tooltips: tooltipOptions,
            scales: {
              yAxes: [{
                gridLines: {display: true},
              }],
              xAxes: [{
                time: {unit: 'day', stepSize: 1},
                type: 'time', gridLines: {display: true}
              }]
            }
          }
        },
        options: {
          tooltips: tooltipOptions,
          scales: {
            yAxes: [{
              ticks: {
                callback: function (value, index, values) {
                  return value + '%';
                }
              },
              gridLines: {display: true}
            }],
            xAxes: [{
              time: {unit: 'day', stepSize: 1},
              type: 'time', ticks: {beginAtZero: true, stepSize: 1, maxTicksLimit: 10},
              gridLines: {display: true}
            }]
          }
        },
        monthlyReturnBalance: {
          labels: [],
          datasets: [
            {
              borderColor: '#fcc468', fill: true,
              backgroundColor: '#fcc468', hoverBorderColor: '#fcc468',
              borderWidth: 8, data: []
            }
          ],
          options: {
            tooltips: tooltipOptions,
            scales: {
              yAxes: [{
                ticks: {
                  callback: function (value, index, values) {
                    return value + '%';
                  }
                },
                gridLines: {display: true}
              }],
              xAxes: [{
                gridLines: {display: true}
              }]
            }
          }
        }
      },
      equityUsersChart: {
        labels: [],
        effectiveDrawdown: {
          data: [],
          options: {
            tooltips: tooltipOptions,
            scales: {
              yAxes: [{
                ticks: {
                  callback: function (value, index, values) {
                    return value + '%';
                  }
                },
                gridLines: {display: true}
              }],
              xAxes: [{
                time: {unit: 'day', stepSize: 1},
                type: 'time', ticks: {beginAtZero: true, stepSize: 1, maxTicksLimit: 10},
                gridLines: {display: true}
              }]
            }
          }
        },
        stats: {},
        percentualVolatilityEndurance: {
          data: [],
          options: {
            tooltips: tooltipOptions,
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  suggestedMax: 100,
                  callback: function (value, index, values) {
                    return value + '%';
                  },
                  reverse: true
                },
                gridLines: {display: true}
              }],
              xAxes: [{
                time: {unit: 'day', stepSize: 1},
                type: 'time', ticks: {beginAtZero: true, stepSize: 1, maxTicksLimit: 10},
                gridLines: {display: true}
              }]
            }
          }
        },
        color: '#6bd098',
        effectiveLeverageUsed: {
          data: [],
          options: {
            tooltips: tooltipOptions,
            scales: {
              yAxes: [{
                gridLines: {display: true},
              }],
              xAxes: [{
                time: {unit: 'day', stepSize: 1},
                type: 'time', gridLines: {display: true}
              }]
            }
          }
        },
        options: {
          tooltips: tooltipOptions,
          scales: {
            yAxes: [{
              ticks: {
                callback: function (value, index, values) {
                  return value + '%';
                }
              },
              gridLines: {display: true}
            }],
            xAxes: [{
              time: {unit: 'day', stepSize: 1},
              type: 'time', ticks: {beginAtZero: true, stepSize: 1, maxTicksLimit: 10},
              gridLines: {display: true}
            }]
          }
        },
        monthlyReturnEquity: {
          labels: [],
          datasets: [
            {
              borderColor: [], fill: true,
              backgroundColor: [], hoverBorderColor: [],
              borderWidth: 8, data: [], fillColor: []
            }
          ],
          options: {
            tooltips: tooltipOptions,
            scales: {
              yAxes: [{
                ticks: {
                  callback: function (value, index, values) {
                    return value + '%';
                  }
                },
                gridLines: {display: true}
              }],
              xAxes: [{
                gridLines: {display: true}
              }]
            }
          }
        },
        accumulatedEquityGrowth: {
          datasets: [
            {
              borderColor: '#51cbce', backgroundColor: '#51cbce',
              data: [], pointRadius: 0, fill: false
            }
          ]
        },
        dailyEquityGrowth: {
          datasets: [
            {
              borderColor: '#51cbce', backgroundColor: '#51cbce',
              data: [], pointRadius: 0, fill: false
            }
          ]
        }
      },
      account: null,
      inprogress: true,
      adminusr: false,
      ueml: null,
      aid: null,
      modal: false,
      modal_alert: false,
      modal_alert_msg: null,
      modal_alert_type: 'danger',
      showdata: false,
      strategy_name: this.$t('notavailable'),
      strategy_acc: this.$t('notavailable'),
      strategy_desc: null,
      balChartInfo: {},
      eqChartInfo: {},
      balReportingStatus: null,
      eqReportingStatus: null,
      modaldesc: false,
      stratinfo: {},
      modalinprogress: false,
      inprogresstxt: this.$t('loadingtxt') + " " + this.$t('strategyinfo') + ". " + this.$t('pleasewait')
    }
  },
  computed: {
    hasHistory() {
      return window.history.length > 1
    }
  },
  methods: {
    closeModal() {
      this.modal_alert = false;
      this.modal_alert_msg = null;
      this.modal_alert_type = 'danger';
    },
    dlMathAnalysis() {
      var request = new XMLHttpRequest();

      const stateChanged = e => {
        if (request.readyState == 2 && request.status == 200) {
          //console.log('Download Started');
        } else if (request.readyState == 3) {
          //console.log("In Progress");
        } else if (request.readyState == 4) {
          //console.log("Download Done");
          window.open('/static/docs/HokoCloud-AnalysisMathematics.pdf', '_top');
          //this.dlall = true;
        }
      };

      request.addEventListener('readystatechange', stateChanged);

      request.addEventListener('progress', function (e) {
        var percent_complete = (e.loaded / e.total) * 100;
        //console.log(percent_complete);
      });

      request.responseType = 'blob';

      // Downloading Doc
      request.open('get', '/static/docs/HokoCloud-AnalysisMathematics.pdf');
      request.send();
    },
    loadedEqPlot(resp) {
      if (!resp.success) {
        this.inprogress = false;
        return;
      }
      let data = resp.data;
      let today = this.$moment('timezone', 'Europe/London', 'LLLL ss').format('YYYY-MM-DD');
      for (let i = 0; i < data.plot.length; i++) {
        let p = data.plot[i];
        let dtday = this.$moment(p.unix_dt * 1000).format("YYYY-MM-DD");
        // Exclude today's
        if (today !== dtday) {
          this.equityUsersChart.labels.push(p.unix_dt * 1000);
          this.equityUsersChart.accumulatedEquityGrowth.datasets[0].data.push(p.accumulatedEquityGrowth * 100);
          this.equityUsersChart.dailyEquityGrowth.datasets[0].data.push(p.dailyEquityGrowth * 100);
          this.equityUsersChart.effectiveDrawdown.data.push(p.effectiveDrawdown * 100);
          this.equityUsersChart.effectiveLeverageUsed.data.push(p.effectiveLeverageUsed);
          if (i > 0) {
            this.equityUsersChart.percentualVolatilityEndurance.data.push(p.percentualVolatilityEndurance * 100);
          }
        }
        //monthlyReturnBalance
        if (p.monthlyReturnEquity) {
          let mo = this.$moment(p.unix_dt * 1000).format("MMM YY");
          //this.activeUsersChart.monthlyReturnBalance.labels.push(p.unix_dt * 1000);
          this.equityUsersChart.monthlyReturnEquity.labels.push(mo);
          const moReturnEq = p.monthlyReturnEquity * 100;
          let mainColor = '#51cbce';
          if (moReturnEq < 0) {
            mainColor = '#ef8157';
          }
          this.equityUsersChart.monthlyReturnEquity.datasets[0].data.push(moReturnEq);
          this.equityUsersChart.monthlyReturnEquity.datasets[0].borderColor.push(mainColor);
          this.equityUsersChart.monthlyReturnEquity.datasets[0].backgroundColor.push(mainColor);
          this.equityUsersChart.monthlyReturnEquity.datasets[0].hoverBorderColor.push(mainColor);
        }
      }
      // Modify Step Size
      if (this.equityUsersChart.labels.length > maxSteps) {
        let stepSize = parseInt(this.equityUsersChart.labels.length / maxSteps);
        this.equityUsersChart.options.scales.xAxes[0].time.stepSize = stepSize;
        this.equityUsersChart.effectiveLeverageUsed.options.scales.xAxes[0].time.stepSize = stepSize;
      }
      // Statistics
      this.equityUsersChart.stats = data.stats;
      // Chart Info
      this.eqChartInfo = {
        "lastupdate": this.$t('lastupdate') + ": " + data.lastupdate,
        "orders_fail": data.orders_fail,
        "symbol_price_fail": data.symbol_price_fail,
        "trade_history_fail": data.trade_history_fail
      };
      this.eqReportingStatus = this.$getChartFlagInfo(this.balChartInfo);
      // Store into Local Storage
      localStorage.setItem('hc_bal_strat_info_' + data.account, JSON.stringify({
        activeUsersChart: Object.assign({}, this.activeUsersChart),
        balChartInfo: Object.assign({}, this.balChartInfo),
        balReportingStatus: this.balReportingStatus,
        equityUsersChart: Object.assign({}, this.equityUsersChart),
        eqChartInfo: Object.assign({}, this.eqChartInfo),
        eqReportingStatus: this.eqReportingStatus,
        lastupdated: this.$moment('timezone', 'Europe/London', 'LLLL ss').add(1, 'h').unix()
      }));
      // Show Data
      this.inprogress = false;
      this.showdata = true;
    },
    loadedPlot(resp, data) {

      if (!resp.success) {
        this.inprogress = false;
        return;
      }
      let respData = resp.data;

      let today = this.$moment('timezone', 'Europe/London', 'LLLL ss').format('YYYY-MM-DD');
      for (let i = 0; i < respData.plot.length; i++) {
        let p = respData.plot[i];
        let dtday = this.$moment(p.unix_dt * 1000).format("YYYY-MM-DD");
        // Exclude today's
        if (today !== dtday) {
          this.activeUsersChart.labels.push(p.unix_dt * 1000);
          this.activeUsersChart.accumulatedBalance.datasets[0].data.push(p.accumulatedBalance * 100);
          this.activeUsersChart.dailyBalanceGrowth.datasets[0].data.push(p.dailyBalanceGrowth * 100);
          this.activeUsersChart.drawdown.data.push(p.drawdown * 100);
          this.activeUsersChart.effectiveLeverageUsed.data.push(p.effectiveLeverageUsed);
          if (i > 0) {
            this.activeUsersChart.percentualVolatilityEndurance.data.push(p.percentualVolatilityEndurance * 100);
          }
        }
        //monthlyReturnBalance
        if (p.monthlyReturnBalance !== null) {
          let mo = this.$moment(p.unix_dt * 1000).format("MMM YY");
          this.activeUsersChart.monthlyReturnBalance.labels.push(mo);
          this.activeUsersChart.monthlyReturnBalance.datasets[0].data.push(p.monthlyReturnBalance * 100);
        }
      }
      // Modify Step Size
      if (this.activeUsersChart.labels.length > maxSteps) {
        let stepSize = parseInt(this.activeUsersChart.labels.length / maxSteps);
        this.activeUsersChart.options.scales.xAxes[0].time.stepSize = stepSize;
        this.activeUsersChart.effectiveLeverageUsed.options.scales.xAxes[0].time.stepSize = stepSize;
      }
      // Statistics
      this.activeUsersChart.stats = respData.stats;
      // Chart Info
      this.balChartInfo = {
        "lastupdate": this.$t('lastupdate') + ": " + respData.lastupdate,
        "orders_fail": respData.orders_fail,
        "symbol_price_fail": respData.symbol_price_fail,
        "trade_history_fail": respData.trade_history_fail
      };
      this.balReportingStatus = this.$getChartFlagInfo(this.balChartInfo);

      // Get Equity Plot
      this.$getPlotDataEquity(data['account'])
          .then(this.loadedEqPlot, this.failop);
    },
    loadStratInfo(data) {

      this.strategy_name = data['sname'] || this.$t('notavailable');
      this.strategy_acc = data['account'] || this.$t('notavailable');
      this.strategy_desc = data['sdesc'];

      // Check local storage before loading strategy
      let now = this.$moment('timezone', 'Europe/London', 'LLLL ss').unix();
      let loadFromLocalStorage = false;
      let chartinfo = localStorage.getItem('hc_bal_strat_info_' + data['account']);
      if (chartinfo) {
        chartinfo = JSON.parse(chartinfo);
        if (now <= chartinfo.lastupdated) {
          loadFromLocalStorage = true;

          const ticks = {
            callback: function (value, index, values) {
              return value + '%';
            }
          };
          this.activeUsersChart = chartinfo.activeUsersChart;
          this.activeUsersChart.options.scales.yAxes[0].ticks = ticks
          this.balChartInfo = chartinfo.balChartInfo;
          this.balReportingStatus = chartinfo.balReportingStatus;

          this.equityUsersChart = chartinfo.equityUsersChart;
          this.equityUsersChart.options.scales.yAxes[0].ticks = ticks
          this.eqChartInfo = chartinfo.eqChartInfo;
          this.eqReportingStatus = chartinfo.eqReportingStatus;
          // Show Data
          this.inprogress = false;
          this.showdata = true;
        }
      }
      // Load from API
      if (!loadFromLocalStorage) {
        this.$getPlotData(data['account'])
            .then(resp => this.loadedPlot(resp, data), this.failop);
      }
    },
    failop(error) {
      console.log(error);
      if (error === "Mismatched UID and AID") {
        error = this.$t('notallowedtoseehistory');
      } else {
        error = error.data.msg.includes('UNEXPECTED') ? this.$t('noinfoonstrattxt') : error.data.msg;
      }
      //this.modal_alert = true;
      this.modal_alert_msg = error;
      this.inprogress = false;
      this.modalinprogress = false;
    },
    prevPage() {
      localStorage.removeItem('_tmp-sid-account');
      window.history.back();
    },
    closeModalDesc() {
      this.modal_alert = false;
      this.modal_alert_type = 'danger';
      this.modal_alert_msg = null;
      this.modaldesc = false;
    },
    updatedDesc(resp) {
      if (resp.success) {
        this.strategy_desc = this.stratinfo.strat_desc
        this.modal_alert = true;
        this.modal_alert_type = 'success';
        this.modal_alert_msg = this.$t('description') + " " + this.$t('successtxt') + " " + this.$t('updatedtxt');
        setTimeout(this.closeModalDesc, timercountdown);
      }
    },
    updateDesc() {
      this.modalinprogress = true;
      console.log(this.stratinfo)
      this.$upsertStrategy(null, this.stratinfo, this.stratinfo.sid, null).then(this.updatedDesc, this.failop);
    },
    loadInfo() {
      let stratdata = localStorage.getItem('_tmp-sid-account');
      if (stratdata) {
        stratdata = JSON.parse(stratdata);
        let discoverable = false;
        if (stratdata.discoverable == 1) {
          discoverable = true;
        }
        this.stratinfo = {
          "strat_name": stratdata.sname,
          "discoverable": discoverable,
          "price": stratdata.price,
          "strat_desc": stratdata.sdesc,
          "sid": stratdata.sid
        };
        this.loadStratInfo(stratdata);
      } else {
        this.failop({
          data: {
            msg: this.$t('noinfoonstrattxt')
          }
        });
      }
    }
  },
  mounted() {
    if (user_data['roles'].indexOf('admin') >= 0) {
      this.adminusr = true;
    }
    let stratdata = localStorage.getItem('_tmp-sid-account');
    if (stratdata) {
      stratdata = JSON.parse(stratdata);
      let discoverable = false;
      if (stratdata.discoverable == 1) {
        discoverable = true;
      }
      this.stratinfo = {
        "strat_name": stratdata.sname,
        "discoverable": discoverable,
        "price": stratdata.price,
        "strat_desc": stratdata.sdesc,
        "sid": stratdata.sid
      };
      this.loadStratInfo(stratdata);
    } else {
      this.failop({
        data: {
          msg: this.$t('noinfoonstrattxt')
        }
      });
    }
  },
  created() {
    this.$setTranslatingInProgress()
  },
  beforeDestroy() {
    this.$root.$off("showTranslatingProgress")
  },
  destroyed() {
    localStorage.removeItem('_tmp-sid-account');
  }
}
</script>
<style lang="scss">
.abit-up {
  position: relative;
  top: -15px;
}

.abit-down {
  position: relative;
  top: 2px;
}

.adjust-width {
  width: 325px;
}

.first-col {
  width: 25%;
}

.label-stat {
  text-transform: uppercase;
  font-weight: bold;
}

.pad-me-right {
  padding-right: 40px !important;
  width: 25%
}

.table {
  margin-bottom: 0;
}

.table-responsive {
  overflow: hidden;
}
</style>
